import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {APP_CONFIG, AppConfig} from "../../config/app.config";
import {Observable} from "rxjs";
import {CharacteristicDto} from "../../DTO/characteristic.dto";
import {ManufacturerDTO} from "../../DTO/manufacturer.dto";

@Injectable({
  providedIn: 'root'
})
export class CharacteristicService {
  private url: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.url = `${this.appConfig.apiEndpoint}/characteristics`;
  }

  public getById(id: number): Observable<CharacteristicDto> {
    const url = `${this.url}/${id}`;
    return this.http.get<CharacteristicDto>(url);
  }

  public getCharacteristicByCategory(
    categoryIds: number[],
    minPrice: number,
    maxPrice: number
  ): Observable<CharacteristicDto[]> {
    const url = `${this.url}/category/`;

    let params = new HttpParams().append('categories', categoryIds.toString());
    params = minPrice > 0 ? params.append('min', minPrice.toString()) : params;
    params = maxPrice > 0 ? params.append('max', maxPrice.toString()) : params;

    const options = { params };

    return this.http.get<CharacteristicDto[]>(url, options);
  }




}
